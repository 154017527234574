var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"homepage"}},[_c('header',{staticClass:"fixed-top d-flex align-items-center header-transparent",attrs:{"id":"header"}},[_c('site-header')],1),_c('carousel',{attrs:{"titlePage":'Hòm thư góp ý'}}),_c('main',{attrs:{"id":"main"}},[_c('section',{staticClass:"team",attrs:{"data-aos":"fade-up","data-aos-easing":"ease-in-out","data-aos-duration":"500"}},[_c('div',{staticClass:"container",attrs:{"id":"legal-document"}},[_c('div',{staticClass:"d-flex"},[_c('feather-icon',{attrs:{"icon":"HomeIcon","size":"20","color":"#0282CD"}}),_c('feather-icon',{staticClass:"ml-1",attrs:{"icon":"ChevronRightIcon","size":"20"}}),_c('div',{staticClass:"titlePage"},[_vm._v(" Góp ý ")])],1),(_vm.dataList && _vm.dataList.length > 0)?_c('div',[_vm._l((_vm.dataList),function(item){return _c('div',{key:item.id,staticClass:"fb-item"},[_c('span',{staticClass:"fb-title"},[_vm._v(_vm._s(item.subject))]),_c('div',{staticClass:"fb-header"},[_c('b-avatar',{staticClass:"fb-user-avatar",attrs:{"size":"32","text":_vm.getNameAlphabet(item.name),"variant":'success'}}),_c('div',{staticClass:"fb-user-info"},[_c('span',[_c('strong',{staticClass:"fb-user-name"},[_vm._v(_vm._s(item.name))]),_c('span',[_vm._v(" | "+_vm._s(_vm._f("formatDateToDDMM")(item.created)))])]),_c('span',[_vm._v(_vm._s(item.email))])])],1),_c('span',{staticClass:"fb-body"},[_vm._v(_vm._s(item.content))]),_c('div',{staticClass:"fb-reply"},[_c('feather-icon',{attrs:{"icon":"CornerDownRightIcon","size":"18"}}),_c('div',{staticClass:"ml-1 fb-reply-content"},[_c('span',[_vm._v(_vm._s(item.feedbackReply))])])],1)])}),_c('my-pagination',{attrs:{"totalItems":_vm.totalRecord,"currentPage":_vm.urlQuery.pageNumber},on:{"pageClick":function (p,t){
              _vm.urlQuery.pageNumber = p
              _vm.urlQuery.pageSize = t
              _vm.fetchData(_vm.urlQuery)
            }}})],2):_vm._e(),_c('div',{staticClass:"mt-1"},[_c('h3',{staticClass:"d-flex justify-content-center"},[_vm._v(" Gửi liên hệ cho chúng tôi ")]),_c('validation-observer',{ref:"rule"},[_c('b-form',{attrs:{"disabled":_vm.disable}},[_c('b-row',[_c('b-col',{attrs:{"lg":"6","sm":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Họ và tên "),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"name","rules":"required","custom-messages":_vm.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.dataInput.name),callback:function ($$v) {_vm.$set(_vm.dataInput, "name", $$v)},expression:"dataInput.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"6","sm":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Email"),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"email","rules":{required: _vm.required, email: _vm.email},"custom-messages":_vm.email},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.dataInput.email),callback:function ($$v) {_vm.$set(_vm.dataInput, "email", $$v)},expression:"dataInput.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"lg":"6","sm":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Số điện thoại")]),_c('validation-provider',{attrs:{"name":"phoneNumber","rules":{
                        regex: /^(0?)(3[2-9]|5[6|8|9]|7[0|6-9]|8[0-6|8|9]|9[0-4|6-9])[0-9]{7}$/},"custom-messages":_vm.phoneNumber},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.dataInput.phoneNumber),callback:function ($$v) {_vm.$set(_vm.dataInput, "phoneNumber", $$v)},expression:"dataInput.phoneNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"6","sm":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Tiêu đề"),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"subject","rules":{required: _vm.required},"custom-messages":_vm.subject},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.dataInput.subject),callback:function ($$v) {_vm.$set(_vm.dataInput, "subject", $$v)},expression:"dataInput.subject"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',[_c('label',[_vm._v("Nội dung"),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"content","rules":"required","custom-messages":_vm.content},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.dataInput.content),callback:function ($$v) {_vm.$set(_vm.dataInput, "content", $$v)},expression:"dataInput.content"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-center"},[_c('b-button',{attrs:{"variant":"warning","disabled":_vm.disable},on:{"click":_vm.submitFeedBack}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"SendIcon"}}),_c('span',[_vm._v("Gửi đi")])],1)],1)],1)],1)])])]),_c('footer',{attrs:{"id":"footer","data-aos":"fade-up","data-aos-easing":"ease-in-out","data-aos-duration":"500"}},[_c('site-footer')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }