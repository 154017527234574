<template>
  <div id="homepage">
    <!-- ======= Header ======= -->
    <header
      id="header"
      class="fixed-top d-flex align-items-center header-transparent"
    >
      <site-header />
    </header>
    <!-- End Header -->

    <!-- ======= Hero Section ======= -->
    <carousel

      :titlePage="'Hòm thư góp ý'"
    />
    <!-- End Hero -->

    <main
      id="main"
    >
      <section
        class="team"
        data-aos="fade-up"
        data-aos-easing="ease-in-out"
        data-aos-duration="500"
      >
        <div
          id="legal-document"
          class="container"
        >
          <div class="d-flex">
            <feather-icon
              icon="HomeIcon"
              size="20"
              color="#0282CD"
            />
            <feather-icon
              icon="ChevronRightIcon"
              size="20"
              class="ml-1"
            />
            <div class="titlePage">
              Góp ý
            </div>
          </div>
          <div v-if="dataList && dataList.length > 0">
            <div
              v-for="item in dataList"
              :key="item.id"
              class="fb-item"
            >
              <span class="fb-title">{{ item.subject }}</span>
              <div class="fb-header">
                <b-avatar
                  size="32"
                  :text="getNameAlphabet(item.name)"
                  :variant="'success'"
                  class="fb-user-avatar"
                />
                <div class="fb-user-info">
                  <span><strong class="fb-user-name">{{ item.name }}</strong><span>   |   {{ item.created | formatDateToDDMM }}</span></span>
                  <span>{{ item.email }}</span>
                </div>
              </div>
              <span class="fb-body">{{ item.content }}</span>
              <div class="fb-reply">
                <feather-icon
                  icon="CornerDownRightIcon"
                  size="18"
                />
                <div class="ml-1 fb-reply-content">
                  <span>{{ item.feedbackReply }}</span>
                </div>
              </div>
            </div>
            <my-pagination
              :totalItems="totalRecord"
              :currentPage="urlQuery.pageNumber"
              @pageClick="(p,t)=> {
                urlQuery.pageNumber = p
                urlQuery.pageSize = t
                fetchData(urlQuery)
              }"
            />
          </div>
          <div
            class="mt-1"
          >
            <h3 class="d-flex justify-content-center">
              Gửi liên hệ cho chúng tôi
            </h3>
            <validation-observer ref="rule">
              <b-form :disabled="disable">
                <b-row>
                  <b-col
                    lg="6"
                    sm="12"
                  >
                    <b-form-group>
                      <label>Họ và tên <span class="required">(*)</span></label>
                      <validation-provider
                        #default="{ errors }"
                        name="name"
                        rules="required"
                        :custom-messages="name"
                      >
                        <b-form-input
                          v-model="dataInput.name"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    lg="6"
                    sm="12"
                  >
                    <b-form-group>
                      <label>Email<span class="required">(*)</span></label>
                      <validation-provider
                        #default="{ errors }"
                        name="email"
                        :rules="{required, email}"
                        :custom-messages="email"
                      >
                        <b-form-input
                          v-model="dataInput.email"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    lg="6"
                    sm="12"
                  >
                    <b-form-group>
                      <label>Số điện thoại</label>
                      <validation-provider
                        #default="{ errors }"
                        name="phoneNumber"
                        :rules="{
                          regex: /^(0?)(3[2-9]|5[6|8|9]|7[0|6-9]|8[0-6|8|9]|9[0-4|6-9])[0-9]{7}$/}"
                        :custom-messages="phoneNumber"
                      >
                        <b-form-input
                          v-model="dataInput.phoneNumber"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    lg="6"
                    sm="12"
                  >
                    <b-form-group>
                      <label>Tiêu đề<span class="required">(*)</span></label>
                      <validation-provider
                        #default="{ errors }"
                        name="subject"
                        :rules="{required}"
                        :custom-messages="subject"
                      >
                        <b-form-input
                          v-model="dataInput.subject"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group>
                      <label>Nội dung<span class="required">(*)</span></label>
                      <validation-provider
                        #default="{ errors }"
                        name="content"
                        rules="required"
                        :custom-messages="content"
                      >
                        <b-form-textarea
                          v-model="dataInput.content"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
            <b-row>
              <b-col class="d-flex justify-content-center">
                <b-button
                  variant="warning"
                  :disabled="disable"
                  @click="submitFeedBack"
                >
                  <feather-icon
                    icon="SendIcon"
                    class="mr-50"
                  />
                  <span>Gửi đi</span>
                </b-button>
              </b-col>
            </b-row>
          </div>
        </div>
      </section>
      <!-- End Team Section -->
    </main>
    <!-- End #main -->

    <!-- ======= Footer ======= -->
    <footer
      id="footer"
      data-aos="fade-up"
      data-aos-easing="ease-in-out"
      data-aos-duration="500"
    >
      <site-footer />
    </footer>
    <!-- End Footer -->

    <!-- <a
      href="#"
      class="back-to-top d-flex align-items-center justify-content-center"
    ><i class="bi bi-arrow-up-short"></i></a> -->
  </div>
</template>

<script>
import { formatDateToDDMM } from '@core/utils/filter'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BForm,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BCol,
  BRow,
  BButton,
  BAvatar,
  VBTooltip,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import { required, email, digits } from '@validations'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import MyPagination from '@/components/pagination/MyPagination.vue'
import Carousel from './components/Carousel.vue'
import ConstantsApi from './constants/ConstantsApi'
import SiteHeader from './components/SiteHeader.vue'
import SiteFooter from './components/SiteFooter.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BRow,
    BAvatar,
    BCol,
    SiteHeader,
    SiteFooter,
    MyPagination,
    VBTooltip,
    Carousel,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  filters: {
    formatDateToDDMM,
  },
  data() {
    return {
      disable: false,
      required,
      digits,
      dataInput: {},
      name: {
        required: 'Họ và tên là bắt buộc',
      },
      email: {
        required: 'Địa chỉ email là bắt buộc',
        email: 'Định dạng email chưa chính xác',
      },
      phoneNumber: {
        regex: 'Định dạng số điện thoại chưa chính xác',
      },
      subject: {
        required: 'Chủ đề là bắt buộc',
      },
      content: {
        required: 'Nội dung là bắt buộc',
      },
      urlQuery: {
        status: '',
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      totalRecord: 0,
      dataList: [],
    }
  },
  mounted() {
    /* eslint-disable no-undef */
    /* eslint-disable no-new */
    // eslint-disable-next-line func-names
    /**
     * Easy selector helper function
     */
    const select = (el, all = false) => {
      el = el.trim()
      if (all) {
        return [...document.querySelectorAll(el)]
      }
      return document.querySelector(el)
    }

    /**
     * Easy event listener function
     */
    const on = (type, el, listener, all = false) => {
      const selectEl = select(el, all)
      if (selectEl) {
        if (all) {
          selectEl.forEach(e => e.addEventListener(type, listener))
        } else {
          selectEl.addEventListener(type, listener)
        }
      }
    }

    /**
     * Easy on scroll event listener
     */
    const onscroll = (el, listener) => {
      el.addEventListener('scroll', listener)
    }

    /**
     * Scrolls to an element with header offset
     */
    const scrollto = el => {
      const header = select('#header')
      let offset = header.offsetHeight

      if (!header.classList.contains('header-scrolled')) {
        offset -= 20
      }

      const elementPos = select(el).offsetTop
      window.scrollTo({
        top: elementPos - offset,
        behavior: 'smooth',
      })
    }

    /**
     * Toggle .header-scrolled class to #header when page is scrolled
     */
    const selectHeader = select('#header')
    if (selectHeader) {
      const headerScrolled = () => {
        if (window.scrollY > 100) {
          selectHeader.classList.add('header-scrolled')
        } else {
          selectHeader.classList.remove('header-scrolled')
        }
      }
      window.addEventListener('load', headerScrolled)
      onscroll(document, headerScrolled)
    }

    /**
     * Back to top button
     */
    const backtotop = select('.back-to-top')
    if (backtotop) {
      const toggleBacktotop = () => {
        if (window.scrollY > 100) {
          backtotop.classList.add('active')
        } else {
          backtotop.classList.remove('active')
        }
      }
      window.addEventListener('load', toggleBacktotop)
      onscroll(document, toggleBacktotop)
    }

    /**
     * Mobile nav toggle
     */
    // eslint-disable-next-line func-names
    on('click', '.mobile-nav-toggle', function (e) {
      select('#navbar').classList.toggle('navbar-mobile')
      this.classList.toggle('bi-list')
      this.classList.toggle('bi-x')
    })

    /**
     * Mobile nav dropdowns activate
     */
    // eslint-disable-next-line func-names
    on(
      'click',
      '.navbar .dropdown > a',
      function (e) {
        if (select('#navbar').classList.contains('navbar-mobile')) {
          e.preventDefault()
          this.nextElementSibling.classList.toggle('dropdown-active')
        }
      },
      true,
    )

    /**
     * Scrool with ofset on links with a class name .scrollto
     */
    // eslint-disable-next-line func-names
    on(
      'click',
      '.scrollto',
      function (e) {
        if (select(this.hash)) {
          e.preventDefault()

          const navbar = select('#navbar')
          if (navbar.classList.contains('navbar-mobile')) {
            navbar.classList.remove('navbar-mobile')
            const navbarToggle = select('.mobile-nav-toggle')
            navbarToggle.classList.toggle('bi-list')
            navbarToggle.classList.toggle('bi-x')
          }
          scrollto(this.hash)
        }
      },
      true,
    )

    /**
     * Skills animation
     */
    const skilsContent = select('.skills-content')
    if (skilsContent) {
      // eslint-disable-next-line no-new
      new Waypoint({
        element: skilsContent,
        offset: '80%',
        handler(direction) {
          const progress = select('.progress .progress-bar', true)
          progress.forEach(el => {
            el.style.width = `${el.getAttribute('aria-valuenow')}%`
          })
        },
      })
    }

    /**
     * Testimonials slider
     */
    // eslint-disable-next-line no-new
    new Swiper('.testimonials-carousel', {
      speed: 400,
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
    })

    /**
     * Porfolio isotope and filter
     */
    window.addEventListener('load', () => {
      const portfolioContainer = select('.portfolio-container')
      if (portfolioContainer) {
        // eslint-disable-next-line no-undef
        const portfolioIsotope = new Isotope(portfolioContainer, {
          itemSelector: '.portfolio-wrap',
          layoutMode: 'fitRows',
        })

        const portfolioFilters = select('#portfolio-flters li', true)

        // eslint-disable-next-line func-names
        on(
          'click',
          '#portfolio-flters li',
          function (e) {
            e.preventDefault()
            portfolioFilters.forEach(el => {
              el.classList.remove('filter-active')
            })
            this.classList.add('filter-active')

            portfolioIsotope.arrange({
              filter: this.getAttribute('data-filter'),
            })
            portfolioIsotope.on('arrangeComplete', () => {
              // eslint-disable-next-line no-undef
              AOS.refresh()
            })
          },
          true,
        )
      }
    })

    /**
     * Initiate portfolio lightbox
     */
    // eslint-disable-next-line no-undef
    const portfolioLightbox = GLightbox({
      selector: '.portfolio-lightbox',
    })

    /**
     * Portfolio details slider
     */
    // eslint-disable-next-line no-new
    // eslint-disable-next-line no-undef
    new Swiper('.portfolio-details-slider', {
      speed: 400,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
    })

    /**
     * Animation on scroll
     */
    window.addEventListener('load', () => {
      // eslint-disable-next-line no-undef
      AOS.init({
        duration: 1000,
        easing: 'ease-in-out',
        once: true,
        mirror: false,
      })
    })

    this.fetchData(this.urlQuery)
  },
  methods: {
    async submitFeedBack() {
      const success = await this.$refs.rule.validate()
      if (success) {
        this.$showAllPageLoading()
        const { data } = await axiosApiInstance.post(ConstantsApi.FEEDBACK_CREATE, this.dataInput)
        if (data.success) {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast('Gửi phản hồi thành công. Chúng tôi sẽ sớm phản hồi thông tin đến bạn. Trân trọng!', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
          this.disable = true
          setTimeout(() => {
            this.$router.push({ name: 'home' })
          }, 2000)
        } else {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast('Không thể gửi phản hồi-góp ý, vui lòng thử lại', {
            title: 'Thông báo',
            variant: 'warning',
            toaster: this.$toastPosition,
            solid: true,
          })
        }
      }
    },
    // Fetch data
    async fetchData(urlQuery) {
      const { data } = await axiosApiInstance.get(ConstantsApi.FEEDBACK_GET, {
        params: urlQuery,
      })
      this.dataList = data?.data?.pageLists
      this.totalRecord = data?.data?.totalRecord
    },
    getNameAlphabet(name) {
      const arr = name.split(' ')
      if (arr.length > 0 && arr[0].length > 0) {
        return arr[0][0]
      } return 'UK'
    },
  },
}
</script>

<style lang="scss">

#main {
  .container {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
    margin-right: auto;
    margin-left: auto;
  }
}

#legal-document {
  .vgt-table {
    th {
      background-color: #227ff4;
      color: #fff !important;
    }
  }
  .text-name {
    color: #227ff4;
  }

  .light-code {
    background: rgba(34, 127, 244, 0.12);
    border-radius: 4px;
  }

  .border-status {
    border-radius: 4px;
  }

  .button-icon {
    .btn-component {
      width: 38px;
      height: 38px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 11px;
    }
  }

  .button-icon-custom {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
  }

  .search-input {
    width: 400px;
  }

  .button-import {
    .input-file {
      display: none;
    }
  }

  .btn-icon-file {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
    label {
      margin-bottom: unset !important;
    }
  }
  .fb-item {
    background: white;
    padding:24px;
    border-radius: 6px;
    margin: 32px 0 32px 0;
    .fb-title {
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
    }
    .fb-header {
      display: flex;
      margin-top: 18px;
      margin-bottom: 12px;
      align-items: center;
    }
    .fb-user-avatar {
        margin-right: 14px;
    }
    .fb-user-info {
        display: flex;
        flex-direction: column;
        .fb-user-name {
          color: #181F28;
        }
        span {
          font-size: 14px;
          line-height: 21px;
          color: #797E84;
        }
    }
    .fb-body {
      font-size: 14px;
      line-height: 21px;
    }
    .fb-reply {
      display: flex;
      margin-top: 20px;
      .fb-reply-content {
        color: var(--dark);
        background: var(--light);
        border-radius: 3px;
        padding: 0.5rem 1rem;
        flex: 1;
      }
    }
}

}
</style>
